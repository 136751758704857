@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono');
@import url('https://fonts.googleapis.com/css?family=Ubuntu');

body {
  font-family: Ubuntu;
  background-color: #242424;
}

.terminal {
  height: calc(100% - 25px);
  margin-top: 1rem;

  .text {
    color: #ddd;
    .template{
      display: block;
      margin-top:0.5rem;
    }

    .social{
      display: inline-flex;
      flex-direction: column;
      justify-content: space-around;
      a {
        color: #ddd;
        font-size: 11pt;
        text-decoration: none;
        padding-top: 0.2rem;
        &:hover{
          color: #606060;
        }
      }
    }


    .prompt {
      display: flex;

      .user {
        color: #87d441;
      }

      .location {
        color: #6d85a9;
      }

      .dollar {
        color: #ddd;
      }

      .command {
        display: flex;
        input.line {
          font-size:10pt;
          background-color: transparent;
          border: 0px solid;
          color: #FFFFFF;
          caret-shape: block;
          caret-color: rgb(0, 400, 0);
          outline: none;
          width: 100%;
        }
      }
    }

  }

  .prompt {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    .user {
      color: #87d441;
    }

    .location {
      color: #6d85a9;
    }

    .dollar {
      color: #ddd;
    }

    .command {
      flex: 1 0 0;
      input {
        width: 100%;
      }
      input.line {
        font-size:10pt;
        background-color: transparent;
        border: 0px solid;
        color: #FFFFFF;
        caret-shape: bar;
        caret-color: rgb(0, 200, 0);
        margin-bottom: .5em;
        outline: none;
      }
    }
  }

}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
